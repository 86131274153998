import React, { useState } from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import { FaFacebookF, FaWhatsapp } from 'react-icons/fa'
import { IoIosMail, IoLogoWhatsapp } from 'react-icons/io'
import { HiLink } from 'react-icons/hi'
import { GrInstagram } from 'react-icons/gr'
import { SiLine } from 'react-icons/si'

import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactMarkdown from 'react-markdown'
import ReactMarkdownWithHtml from 'react-markdown/with-html'
import SEO from '../components/SEO'
import Register from "../components/mailchimp-register"
import {
  FacebookShareButton,
  LineShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'

function validateYouTubeUrl(url)
{
  if (url != undefined || url != '') {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return true
    }
    else {
      return false
    }
  }
}

const paragraphRenderer = ({ children }) => {
  return (
    <p className="mb-4 md:mb-8 text-15-22 text-gray-4d font-regular md:text-16-36">
      {children}
    </p>
  )
}

const htmlRenderer = ({value}) => {
  console.log(value)
  if(typeof document !== "undefined"){
    const EL = document.createElement('div')
    EL.innerHTML = value
    if(EL.firstChild.tagName.toLowerCase() === 'iframe'){
      const Iframe = EL.firstChild
      if( validateYouTubeUrl(Iframe.getAttribute('src')) ){
        return (
          <div className="video-wrapper mt-10" dangerouslySetInnerHTML={{ __html: value }} />
        )
      }

    }
  }
  return (
    <span dangerouslySetInnerHTML={{ __html: value }} />
  )
}

const headingRenderer = ({ children, level }) => {
  const Tag = `h${level}`
  return (
    <Tag className="mb-4 md:mb-8 text-gray-4d font-bold text-17-25 md:text-20-25">
      {children}
    </Tag>
  )
}

const listRenderer = ({ children }) => {
  return (
    <ul className="mb-4 md:mb-8 text-gray-4d font-regular text-15-22 md:text-16-36">
      {children}
    </ul>
  )
}

const blockquoteRender = (props) => {
  const children = props.children
  return (
    <blockquote className="mb-4 mt-4 bg-gray-f2 px-6 py-4 py-10 whitespace-pre-line md:my-8">
      {children}
    </blockquote>
  )
}

const linkRenderer = ({ children, href }) => {
  const igUrlCheck = /^(https?:\/\/)?(www\.)?instagram.com\/[a-zA-Z0-9(\.\?)?]/
  if (href.match(igUrlCheck)) {
    return <a href={href}>{children}</a>
  } else {
    return <a href={href}>{children}</a>
  }
}

const imageRenderer = ({ src, alt }) => {
  const isVideo = src.split('.').pop() === 'mp4'
  return (
    <>
      {!isVideo ? (
        <img src={src} alt={alt} />
      ) : (
        <video
          className="w-full h-auto mb-4 md:mb-8 focus:outline-none"
          controls
        >
          <source src={src} type="video/mp4" />
        </video>
      )}
    </>
  )
}

const BlogPostTemplate = ({ data }) => {
  const [imageLoad, setImageLoad] = useState(false)
  const siteTitle = data.site.siteMetadata.title
  const igLink = data.site.siteMetadata.igLink
  const siteUrl = data.site.siteMetadata.siteUrl
  const post = data.contentfulBlogPost
  const title = `${post.title} | ${siteTitle}`
  const description = post.description.childMarkdownRemark.html
  const description_escape = post.description.description
  const slug = post.slug
  // const currentUrl = typeof window !== 'undefined' ? window.location.href : '';
  const currentUrl = encodeURI(`${siteUrl}/story/${slug}`)
  const mailSubject = post.title
  const ogImage = post.ogImage.fixed.src
  const { category } = post
  const categoryLink = `/stories/${category.slug}`
  const {showRegister} = post
  const mailBody = `${post.title}
  
文章連結：${currentUrl}

${description_escape}
`
  // console.log(data)
  // const [copyBoard,setCoyBoard] = useState({
  //   value:'',
  //   copied:false
  // })

  // console.log(description);
  return (
    <Layout>
      <SEO
        title={post.title}
        description={description_escape}
        image={`https:${ogImage}`}
      />
      {/*<Helmet title={title} />*/}
      <div>
        <div className="wrap px-6 mx-auto py-8 font-30 md:container md:px-10 md:max-w-4xl">
          <div className="px-2">
            <h1 className="uppercase font-bold text-gray-33 text-17-25 text-center md:text-42-58">
              {post.title}
            </h1>
            <div
              className="font-15-22 text-center mt-1 text-gray-4d md:text-25-43"
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            <div className="border-b border-gray-66 pt-4 mb-4 md:pt-6 md:mb-6" />
            <div className="flex mb-4 md:mb-6">
              <div className="flex border-r-0 border-gray-66 items-center w-1/2 sm:border-r-0">
                <div className="flex items-center justify-between font-light text-12 text-gray-66 w-full pr-2 md:text-15">
                  <div>
                    <Link to={categoryLink}>
                      <span className="block sm:inline-block mb-2 sm:mb-0">
                        {category.title}
                      </span>
                    </Link>
                    <span className="hidden sm:inline-block mx-1">|</span>
                    {post.publishDate}
                  </div>
                </div>
              </div>
              <div className="flex w-1/2 justify-end items-center text-gray-66">
                <CopyToClipboard
                  text={currentUrl}
                  onCopy={() => {
                    alert('已複製文章網址．')
                  }}
                >
                  <div className="w-8 h-8 border-gray-66 rounded-full border-2 flex items-center cursor-pointer justify-center mr-1 md:mr-3">
                    <HiLink />
                  </div>
                </CopyToClipboard>
                <FacebookShareButton
                  quote={title}
                  hashtag={siteTitle}
                  url={currentUrl}
                >
                  <div className="w-8 h-8 border-gray-66 rounded-full border-2 flex items-center justify-center mr-1 md:mr-3">
                    <FaFacebookF />
                  </div>
                </FacebookShareButton>
                <LineShareButton url={currentUrl} title={siteTitle}>
                  <div className="w-8 h-8 border-gray-66 rounded-full border-2 flex items-center justify-center mr-1 md:mr-3 text-2xl">
                    <SiLine className="text-xl" />
                  </div>
                </LineShareButton>
                <WhatsappShareButton url={currentUrl} title={siteTitle}>
                  <div className="w-8 h-8 border-gray-66 rounded-full border-2 flex items-center justify-center mr-1 md:mr-3 text-2xl">
                    <FaWhatsapp className="text-lg" />
                  </div>
                </WhatsappShareButton>
                {/*<a href={igLink} target="_blank">*/}
                {/*  <div className="w-8 h-8 border-gray-66 rounded-full border-2 flex items-center justify-center mr-0 text-lg">*/}
                {/*    <GrInstagram/>*/}
                {/*  </div>*/}
                {/*</a>*/}
              </div>
            </div>
          </div>
          <Img
            alt={post.title}
            fluid={post.heroImage.fluid}
            onLoad={() => {
              setImageLoad(true)
            }}
            className={`transition-all duration-700 delay-500 ease-out ${
              imageLoad ? 'opacity-100' : 'opacity-0'
            }`}
          />
          <div className="markdown px-5 mt-8 md:mb-20 md:px-0">
            <ReactMarkdown
              children={post.body.body}
              allowDangerousHtml
              renderers={{
                paragraph: paragraphRenderer,
                heading: headingRenderer,
                list: listRenderer,
                blockquote: blockquoteRender,
                link: linkRenderer,
                image: imageRenderer,
                html: htmlRenderer,
              }}
            />
            {/*<ReactMarkdownWithHtml*/}
            {/*  children={post.body.childMarkdownRemark.html}*/}
            {/*  allowDangerousHtml*/}
            {/*  renderers={{*/}
            {/*    paragraph:paragraphReanderer*/}
            {/*  }}/>*/}
          </div>
        </div>
      </div>
      {showRegister ? <Register/> : null}

    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        igLink
        siteUrl
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      showRegister
      publishDate(formatString: "D MMMM, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000") {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      ogImage: heroImage {
        fixed(width: 1200, height: 630, quality: 10) {
          ...GatsbyContentfulFixed_withWebp
        }
      }
      body {
        body
        childMarkdownRemark {
          html
        }
      }
      description {
        description
        childMarkdownRemark {
          html
        }
      }
      category {
        slug
        title
      }
    }
  }
`
